import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
import DateRangePicker from './components/DateRangePicker';
import moment from 'moment';
export default {
  name: 'AssetsDispatchApplyLogCommit',
  mixins: [common, scrollList, getPageList],
  components: {
    DateRangePicker
  },
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      // initParams: { // 初始化参数
      //
      // },
      searchKey: {
        page: 1,
        size: 20,
        times: null
      },
      // 搜索参数
      listData: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.resetParams();
      await this.getListData();
    },
    goDetail(item) {
      this.$router.push({
        name: 'AssetsDispatchApplyDetail',
        query: {
          allocateApplyId: item.allocateApplyId,
          from: 'current'
        }
      });
    },
    onDateChange() {
      this.initData();
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          page,
          size,
          times
        } = this.searchKey;
        let {
          dcProjectId
        } = this.projectInfo;
        let response = await assetsApi.getAllocateApplyListCurrent({
          pageNum: page,
          pageSize: size,
          projectId: dcProjectId,
          startDate: times && times[0] ? moment(times[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          endDate: times && times[1] ? moment(times[1]).format('YYYY-MM-DD HH:mm:ss') : undefined
        });
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '200') {
          // 请求成功
          let hasMore = false;
          let resultData = result.data.result.records || [];
          hasMore = page < result.data.result.pages;
          if (!isPullDown) {
            this.listData.push(...resultData);
          } else {
            this.listData = [...resultData];
          }
          if (!hasMore) {
            this.isLastPage = true;
          }
          await this.handleCallback({
            isError: false,
            hasMore
            // noDataMessage: ' ',
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    onCancel(item) {
      this.$dialog.confirm({
        message: '请确认是否撤销该调拨申请，撤销后无法恢复。'
      }).then(() => {
        assetsApi.revokeAllocateApply({
          allocateApplyId: item.allocateApplyId
        }).then(res => {
          this.$toast('撤回成功');
          item.reviewStatus = 4;
        });
      });
    }
  }
};